<script lang="ts">
  export let size = 32;
  let className = "";
  export { className as class };
</script>

<svg
  width={size}
  height={size}
  class={className}
  viewBox="0 0 32 32"
  fill="currentColor"
  stroke="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="share-2">
    <g id="Group 4">
      <path
        id="Vector"
        d="M6.33333 16C6.33333 17.2887 5.28866 18.3333 4 18.3333C2.71134 18.3333 1.66667 17.2887 1.66667 16C1.66667 14.7113 2.71134 13.6667 4 13.6667C5.28866 13.6667 6.33333 14.7113 6.33333 16Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M18.3333 16C18.3333 17.2887 17.2887 18.3333 16 18.3333C14.7113 18.3333 13.6667 17.2887 13.6667 16C13.6667 14.7113 14.7113 13.6667 16 13.6667C17.2887 13.6667 18.3333 14.7113 18.3333 16Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M18.3333 4C18.3333 5.28866 17.2887 6.33333 16 6.33333C14.7113 6.33333 13.6667 5.28866 13.6667 4C13.6667 2.71134 14.7113 1.66667 16 1.66667C17.2887 1.66667 18.3333 2.71134 18.3333 4Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M30.3333 16C30.3333 17.2887 29.2887 18.3333 28 18.3333C26.7113 18.3333 25.6667 17.2887 25.6667 16C25.6667 14.7113 26.7113 13.6667 28 13.6667C29.2887 13.6667 30.3333 14.7113 30.3333 16Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_5"
        d="M18.3333 28C18.3333 29.2887 17.2887 30.3333 16 30.3333C14.7113 30.3333 13.6667 29.2887 13.6667 28C13.6667 26.7113 14.7113 25.6667 16 25.6667C17.2887 25.6667 18.3333 26.7113 18.3333 28Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_6"
        d="M10.3333 9C10.3333 9.73638 9.73638 10.3333 9 10.3333C8.26362 10.3333 7.66667 9.73638 7.66667 9C7.66667 8.26362 8.26362 7.66667 9 7.66667C9.73638 7.66667 10.3333 8.26362 10.3333 9Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_7"
        d="M24.3333 9C24.3333 9.73638 23.7364 10.3333 23 10.3333C22.2636 10.3333 21.6667 9.73638 21.6667 9C21.6667 8.26362 22.2636 7.66667 23 7.66667C23.7364 7.66667 24.3333 8.26362 24.3333 9Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_8"
        d="M24.3333 22C24.3333 22.7364 23.7364 23.3333 23 23.3333C22.2636 23.3333 21.6667 22.7364 21.6667 22C21.6667 21.2636 22.2636 20.6667 23 20.6667C23.7364 20.6667 24.3333 21.2636 24.3333 22Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_9"
        d="M10.3333 22C10.3333 22.7364 9.73638 23.3333 9 23.3333C8.26362 23.3333 7.66667 22.7364 7.66667 22C7.66667 21.2636 8.26362 20.6667 9 20.6667C9.73638 20.6667 10.3333 21.2636 10.3333 22Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </g>
</svg>
